import { Component, HostListener, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { CurrentRouteService } from '../services/current-route.service';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute, ActivationEnd, ActivationStart, Event, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  routeClassNames: any = {
    '/products/core-hr': 'coreHrMenuBgSec',
    '/': 'coreHrMenuBgSec',
    '/hr-glossary': 'coreHrMenuBgSec',
    '/products/attendance-management': 'coreHrMenuBgSec',
    '/products/employee-management': 'coreHrMenuBgSec',
    '/products/payroll-management': 'coreHrMenuBgSec',
    '/integrations': 'coreHrMenuBgSec',
    '/resources': 'coreHrMenuBgSec',
    '/blog': 'coreHrMenuBgSec',
    '/client-stories': 'coreHrMenuBgSec',
    '/usecases': 'coreHrMenuBgSec',
    '/leave-management': 'coreHrMenuBgSec',
    '/people-analytics': 'coreHrMenuBgSec',
    '/client-stories-page': 'coreHrMenuBgSec',
    '/pricing-page': 'coreHrMenuBgSec',
    '/contact-us': 'coreHrMenuBgSec',
    '/product-demo': 'coreHrMenuBgSec',
    '/demo': 'coreHrMenuBgSec',
    '/login': 'coreHrMenuBgSec',
    '/about-us': 'coreHrMenuBgSec',
    '/help-center': 'coreHrMenuBgSec',



  };

  routeClassNamesFixed: any = {

    // '/blog/blog-detail': 'coreHrMenuBgSecFixed',
    '/blog-detail': 'coreHrMenuBgSecFixed',
    // '/blog': 'coreHrMenuBgSecFixed',
  
    '/integrations': 'integrationBgSecFixed',
    '/integrations#business-platform': 'integrationBgSecFixed',
    '/integrations#government-platform': 'integrationBgSecFixed',
    '/use-cases': 'useCaseMainHeader',
    '/use-cases#job-function': 'useCaseMainHeader',
    '/use-cases#industries': 'useCaseMainHeader',
    '/use-cases#business-size': 'useCaseMainHeader',
    '/leave-management': 'leaveManagementMainHeader',
    '/attendance-management': 'AttendanceManagementMainHeader',
    '/payroll-management': 'PayrollManagementMainHeader',
    '/people-analytics': 'PeopleAnalyticsMainHeader',
    '/client-stories-page': 'coreHrMenuBgSecFixed',
    '/contact-us': 'contactUsBgSec',
    '/blog': 'blogBgSec',
    '/client-stories': 'blogBgSec',
    '/help-center/:slug': 'coreHrMenuBgSecFixed',

  };

  en: string = 'EN';

  userScrolledFromTop: boolean = false;
  userMouseEnter: boolean = false;
  aboutUsSubBlock: boolean = false;
  resourceSubBlock: boolean = false;
  solutionSubBlock: boolean = false;
  productSubBlock: boolean = false;
  slug: any = null;
  slugH: any = null;
  offcanvasBoolean: boolean = false;


  navHeight = 70;
  lastScrollY = 0;
  delta = 10;
  didScroll = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.userScrolledFromTop = window.scrollY > 20;
    this.scrolled();



    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
    const clientHeight = document.documentElement.clientHeight + 1600;

    const totalHeight = scrollHeight - clientHeight;
    const scrollProgress = (scrollTop / totalHeight) * 100;



    // Update the CSS variable 
    document.documentElement.style.setProperty('--progressBar-width', `${scrollProgress}%`);

  }



  scrolled(): void {
    let sy = window.scrollY;
    if (Math.abs(this.lastScrollY - sy) > this.delta) {
      if (sy > this.lastScrollY && sy > this.navHeight) {
        this.didScroll = false;
      }
      this.lastScrollY = sy;
    }
  }


  langDrop() {
    this.didScroll = !this.didScroll;
  }


  constructor(private currentRouteservice: CurrentRouteService, private activeRoute: Router, private renderer: Renderer2) { }
  route = this.currentRouteservice.getRoute();

  ngOnInit() {
    this.activeRoute.events.subscribe((event: Event) => {
      if (event instanceof ActivationEnd) {
        this.slug = event.snapshot.routeConfig?.path === ':slug'
        this.slugH = event.snapshot.routeConfig?.path === 'help-center/:slug'

     


      }
    })
  }

  setOffcanvas(data: boolean) {
    this.offcanvasBoolean = data;
  }

  getRouteClass() {
    const route = this.currentRouteservice.getRoute();

    if (this.slug) {
      
      return 'blogdetailsMenuBgSecFixed'
    }

    
    if (this.slugH) {
      return 'blogdetailsMenuBgSecFixed'
    }

    if (this.routeClassNamesFixed[route]) {
      return this.routeClassNamesFixed[route]
    }
    return ''
  }

  handleViewSub(type: string) {

    switch (type) {
      case 'about':
        this.aboutUsSubBlock = true;
        break;
      case 'resource':
        this.resourceSubBlock = true;
        break;
      case 'solution':
        this.solutionSubBlock = true;
        break;
      case 'product':
        this.productSubBlock = true;
        break;
    }
  }

  handleRemoveSubBlock() {
    this.aboutUsSubBlock = false;
    this.resourceSubBlock = false;
    this.solutionSubBlock = false;
    this.productSubBlock = false;
  }

  combineClassNames(): string {
    const class1 = this.getRouteClass();
    const class2 = this.handleAddClass();
    return [class1, class2].filter(Boolean).join(' ');
  }

  handleAddClass(): string {
    const route = this.currentRouteservice.getRoute();
    if (this.userScrolledFromTop) {
      if (this.routeClassNames[route]) {

        return this.routeClassNames[route];
      }
      return 'coreHrMenuBgSecFixed'
    } else {
      return '';
    }
  }

  setMouseEnter() {
    this.userMouseEnter = true;
  }

  setMouseLeave() {
    this.userMouseEnter = false;
  }
  setLang(data: string) {
    this.en = data;
  }

}
