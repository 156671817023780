import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./home-page/home-page.module').then((m) => m.HomePageModule),
  },
  {
    path: 'core-hr',
    loadChildren: () =>
      import('./core-hr-product-page/core-hr-product-page.module').then(
        (m) => m.CoreHrProductPageModule
      ),
  },
  {
    path: 'employee-management',
    loadChildren: () =>
      import('./employee-management/employee-management.module').then(
        (m) => m.EmployeeManagementModule
      ),
  },
  {
    path: 'attendance-management',
    loadChildren: () =>
      import('./time-and-attendance/time-and-attendance.module').then(
        (m) => m.TimeAndAttendanceModule
      ),
  },
  {
    path: 'hr-glossary',
    loadChildren: () =>
      import('./hr-glossary/hr-glossary.module').then(
        (m) => m.HrGlossaryModule
      ),
  },

  {
    path: 'payroll-management',
    loadChildren: () =>
      import('./payroll-management/payroll-management.module').then(
        (m) => m.PayrollManagementModule
      ),
  },
  {
    path: 'integrations',
    loadChildren: () =>
      import('./integrations/integrations.module').then(
        (m) => m.IntegrationsModule
      ),
  },
  {
    path: 'resources',
    loadChildren: () =>
      import('./resources/resources.module').then((m) => m.ResourcesModule),
  },
  {
    path: 'blog',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'client-stories',
    loadChildren: () => import('./blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'use-cases',
    loadChildren: () =>
      import('./usecases/usecases.module').then((m) => m.UsecasesModule),
  },
  {
    path: 'leave-management',
    loadChildren: () =>
      import('./leave-management/leave-management.module').then(
        (m) => m.LeaveManagementModule
      ),
  },
  {
    path: 'people-analytics',
    loadChildren: () =>
      import('./people-analytics/people-analytics.module').then(
        (m) => m.PeopleAnalyticsModule
      ),
  },
  {
    path: 'client-stories-page',
    loadChildren: () =>
      import('./client-stories-pages/client-stories-pages.module').then(
        (m) => m.ClientStoriesPagesModule
      ),
  },
  { path: 'pricing', loadChildren: () => import('./pricing-page/pricing-page.module').then(m => m.PricingPageModule) },
  { path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
  { path: 'product-demo', loadChildren: () => import('./product-demo/product-demo.module').then(m => m.ProductDemoModule) },
  { path: 'demo', loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'authentication', loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },

  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
  { path: '404', loadChildren: () => import('./default-page/default-page.module').then(m => m.DefaultPageModule) },
  { path: 'help-center', loadChildren: () => import('./help-center/help-center.module').then(m => m.HelpCenterModule) },
  { path: 'help-center-list/:id', loadChildren: () => import('./help-center-list/help-center-list.module').then(m => m.HelpCenterListModule) },

  { path: 'help-center/:slug', loadChildren: () => import('./help-center-details/help-center-details.module').then(m => m.HelpCenterDetailsModule) },
 
  

  {
    path: ':slug',
    loadChildren: () =>
      import('./blog-detail/blog-detail.module').then(
        m => m.BlogDetailModule
      ),
  },
 

];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  initialNavigation: 'enabledBlocking',
  // scrollOffset: [0, 64] // optional, if you have a fixed header
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions),

  ],

  exports: [RouterModule],
})
export class AppRoutingModule { }

// Demo changes
