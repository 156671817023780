<section class="loaderContainer" >

    <div class="loaderMainWrapper">

        <div class="loaderImgWrapper">
            <img src="../../assets/images/loaderImage_temp.svg" alt="loader-img"/>
        </div>
        <div class="loaderDots">
           <ul>
            <li></li>
            <li></li>
            <li></li>
           </ul>
        </div>

    </div>

</section>