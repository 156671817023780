import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CurrentRouteService {
  constructor(private router:Router) { }

  getRoute(){
    return this.router.url
  }
}
