import { Component } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})


export class FooterComponent {

  lang: string = "EN";



  setLang(data: string) {
    this.lang = data;
  }

}
