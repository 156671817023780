import { Component, HostListener, OnInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';

import { LoadingService } from './services/loading.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'infithra-ssr';
  navHeight = 70;
  lastScrollY = 0;
  delta = 10;
  didScroll = false;
  isLoading = true;

  constructor(private router: Router, private loadingService: LoadingService,
    private cdRef: ChangeDetectorRef) {
    this.isRouteShow()
  }

  routeArray: string[] = ['/login', '/authentication']

  isRouteShow() {
    const route = this.router.url;
    // console.log(route)
    if (!this.routeArray.includes(route)) {
      return true
    }
    return false
  }



  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled();
  }

  scrolled(): void {
    let sy = window.scrollY;
    if (Math.abs(this.lastScrollY - sy) > this.delta) {
      if (sy > this.lastScrollY && sy > this.navHeight) {
        this.didScroll = true;
      }
      else if (sy < this.lastScrollY) {
        this.didScroll = false;
      }
      this.lastScrollY = sy;
    }
  }

  ngOnInit(): void {
    this.loadingService.show();
    this.simulateFullPageLoad().then(() => {
      this.loadingService.hide();
      this.checkAllResourcesLoaded();
    });
  }

  simulateFullPageLoad(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  checkAllResourcesLoaded() {

    this.isLoading = false;
    this.cdRef.detectChanges();
  }


}
