import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HeaderTopBoxComponent } from './header-top-box/header-top-box.component';
import { CurrentRouteService } from './services/current-route.service';
import { ScheduleDemoComponent } from './components/schedule-demo/schedule-demo.component';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoadingComponent } from './loading/loading.component';
import { MatSliderModule } from '@angular/material/slider';
import { RouterModule } from '@angular/router';









@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HeaderTopBoxComponent,
    LoadingComponent,


  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    CarouselModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSliderModule,
    RouterModule,


  ],
  providers: [CurrentRouteService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
